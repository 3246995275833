<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
            <el-carousel-item>
                <img src="@/assets/homePage/ja-banner.png" class="images">
            </el-carousel-item>
        </el-carousel>
        <!-- 中间内容区域 -->
        <div class="content">
            <h2 class="H2">人事理念</h2>
            <el-card>
                <div class="idea">
                    <div>
                        <h2>盛泰人材観</h2>
                        <p>「人を第一とし、人がその才能を尽くし、互いに成果をあげ、共に発展する」という人材観念のもと、人材を常に第一に置き、そして人材が強い企業、また「志を同じくする人を探し、一生と言えることをする。」という目標の実現に力を尽くしています。当社は、人材を求める際、海納百川求同存異の方針を貫き、社員一人一人の価値と創造力を十分に肯定し、同時に社員一人一人に十分な発展空間を提供します。
                        </p>
                    </div>
                    <div class="imageBox">
                        <img src="@/assets/join/图片1.png" alt="" />
                    </div>
                </div>
            </el-card>
            <el-card style="margin-top: 5rem;">
                <div class="cardBox" v-for="item in dataList" :key="item.id">
                    <div class="images">
                        <img class="iconImg" :src="item.urlIcon">
                    </div>
                    <div class="text">
                        <div class="text_1">
                            <h2>{{ item.header }}</h2>
                            <p style="font-size: 1.2rem;">
                                {{ item.content }}
                            </p>
                            <div class="text_2"></div>
                        </div>
                    </div>
                    <img :src="item.urlColor" class="colorImg" alt="">
                </div>
            </el-card>
        </div>

        <!-- 底部 -->
        <foot></foot>
    </div>
    <div class="_div">
        <!-- 头部 -->
        <move_header></move_header>
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                <img src="@/assets/homePage/ja-banner.png" class="images">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容区域 -->
        <div class="content">
            <h2 class="H2">人事理念</h2>
            <el-card>
                <div class="idea">
                    <div>
                        <h1>盛泰人材観</h1>
                        <p>「人を第一とし、人がその才能を尽くし、互いに成果をあげ、共に発展する」という人材観念のもと、人材を常に第一に置き、そして人材が強い企業、また「志を同じくする人を探し、一生と言えることをする。」という目標の実現に力を尽くしています。当社は、人材を求める際、海納百川求同存異の方針を貫き、社員一人一人の価値と創造力を十分に肯定し、同時に社員一人一人に十分な発展空間を提供します。
                        </p>
                    </div>
                    <img class="imagess" src="@/assets/join/图片1.png" alt="">
                </div>
            </el-card>
            <el-card style="margin-top: 5rem;">
                <div class="cardBoxMove" v-for="item in dataList" :key="item.id">
                    <div class="images">
                        <img class="iconImg" :src="item.urlIcon">
                    </div>
                    <div class="text">
                        <h1>{{ item.header }}</h1>
                        <p> {{ item.content }}</p>
                    </div>
                </div>
            </el-card>
        </div>
        <!-- 底部 -->
        <move_foot></move_foot>
    </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
    components: { foot, headers, move_header, move_foot },

    setup() {
        const route = useRoute();
        const state = reactive({
            dataList: [
                { id: 1, urlIcon: require('@/assets/join/人才理念图标/人才管理.png'), urlColor: require('@/assets/join/绿色.png'), header: '人材管理', content: '企業の戦略配置と組織発展のニーズから出発して、盛泰人材梯隊の建設をめぐって異なる人材に対する多様化の管理体系を形成し、積極的で良好な人材成長環境を作りまる。' },
                { id: 2, urlIcon: require('@/assets/join/人才理念图标/人才培养.png'), urlColor: require('@/assets/join/蓝色.png'), header: '人材育成', content: '当社は、適性指導、適地敵作を主張し、仕事の中で育成し、実践の中で発展し、社員が自分の発展と会社の長期計画を結合することを提唱し、科学的な訓練メカニズムを通じて社員に多種の成長経路と発展モデルを提供しています。' },
                { id: 3, urlIcon: require('@/assets/join/人才理念图标/发展空间.png'), urlColor: require('@/assets/join/橙色.png'), header: '発展空間', content: '当社は、人材発展の空間を広げ、人材発展のプラットフォームを構築するため、常に探索を続けています。そして、新時代の急速な発展のリズムの下で、公開と透明を原則として、 「明るい人材」という 体制を確立し、権力運営の規範化建設を推進し、会社と社員は共生関係であることを明確しています。また、企業の発展はまず人に頼りという観念を提唱し、社員の発展に注目すると同時に、社員の価値の最大化をさらに実現することを目指しています。' },
                { id: 4, urlIcon: require('@/assets/join/人才理念图标/薪资福利.png'), urlColor: '', header: '給与と福利', content: '当社は、公平と健全な競争原則を堅持し、社員一人一人に相応な給与と社会保険などの関連福利を提供し、才能を際立たせ、安心して生活し、長期的に発展することができ、また優秀な人材を優遇し、良質な資源をそのような人材に向けます。' },
            ]
        });
        onMounted(() => {
            window.scrollTo(0, 0)
            // let D = document.getElementsByClassName('imagess')
            // // 判断是否移动设备
            // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            //     // D[0].style.marginTop = '15rem'

            // } else {
            //     D[0].style.marginTop = '0'
            // }
            let box = document.getElementsByClassName('cardBox')
            let text_1 = document.getElementsByClassName('text_1')
            let text_2 = document.getElementsByClassName('text_2')
            let colorImg = document.getElementsByClassName('colorImg')
            let iconImg = document.getElementsByClassName('iconImg')
            let cardBoxMove = document.getElementsByClassName('cardBoxMove')
            for (let i = 0; i < box.length; i++) {
                if (i % 2 == 0) {
                    box[i].style.flexDirection = 'row'
                    colorImg[i].style.left = '14rem'
                    colorImg[i].style.bottom = '-14rem'
                    iconImg[i].style.marginLeft = '8rem'
                    iconImg[i].style.marginTop = '5rem'
                    cardBoxMove[i].style.flexDirection = 'row'
                } else {
                    text_2[i].style.left = '20px'
                    text_2[i].style.right = '0'
                    box[i].style.flexDirection = 'row-reverse'
                    colorImg[i].style.right = '14rem'
                    colorImg[i].style.bottom = '-16rem'
                    iconImg[i].style.marginRight = '10rem'
                     cardBoxMove[i].style.flexDirection = 'row-reverse'
                }
                if (i == 0) {
                    text_1[i].style.backgroundColor = '#3863BE'
                    text_2[i].style.borderColor = '#3863BE'
                    box[i].style.marginTop = '2rem'
                } else if (i == 1) {
                    text_1[i].style.backgroundColor = '#099C6D'
                    text_2[i].style.borderColor = '#099C6D'
                } else if (i == 2) {
                    text_1[i].style.backgroundColor = '#15A4DF'
                    text_2[i].style.borderColor = '#15A4DF'
                } else if (i == 3) {
                    text_1[i].style.backgroundColor = '#E67F2C'
                    text_2[i].style.borderColor = '#E67F2C'
                    box[i].style.marginBottom = '5rem'
                }
            }

        });
        return {
            ...toRefs(state),
        };
    },
};
</script>

<style scoped lang="less">
.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

.box {

    width: 100%;

    .content {
        max-width: 1920px;
        margin: 0 auto;
        width: 70%;
        box-sizing: border-box;

        .cardBox {
            position: relative;
            width: 100%;
            display: flex;
            margin: 200px 0;

            // flex-direction: row-reverse;
            .images {
                flex: 1;
                text-align: center;
                box-sizing: border-box;

                img {
                    width: 160px;
                    height: 160px;
                    margin-top: 50px;

                }
            }

            .colorImg {

                position: absolute;
                width: 65%;
            }

            .text {
                flex: 3;

                .text_1 {
                    position: relative;
                    margin: 0 auto;

                    .text_2 {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 30px;
                        right: 30px;
                        border: 1px solid;
                        border-color: #ccc;
                    }

                    h1 {
                        margin: 0;
                        padding: 0;
                    }

                    width: 700px;
                    padding: 30px;
                    box-sizing: border-box;
                    height: 250px;
                    // border: 1px solid #ccc;
                    color: #fff;
                }

                .text_1::after {
                    content: '';
                    width: 0;
                    height: 0;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    border: 15px solid #fff;
                    border-top-color: transparent;
                    border-left-color: transparent;
                }

                .text_1::before {
                    content: '';
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 15px solid #fff;
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                }
            }
        }

        img {
            width: 100%;
        }

        .idea {
            display: flex;

            div,
            img {
                flex: 1;
            }

            .imageBox {

                img {
                    width: 100%;
                    height: 430px;
                }
            }


            div {
                width: 100%;

                p {
                    box-sizing: border-box;
                    padding-right: 50px;
                    font-size: 1.2rem;
                }
            }
        }

        .H2 {
            margin-top: 50px;
            font-size: 1.7rem;
        }

        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 18%;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }
    }

}

.images {
    width: 100%;
    height: auto;
    max-height: 600px;
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;
    // max-height: 700px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

::v-deep .el-card {
    border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
    .box {
        display: block;
    }

    ._div {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .box {
        display: none;
    }

    ._div {
        display: block;

        .content {
            padding: 30px;
            box-sizing: border-box;

            img {
                width: 100%;
            }

            .idea {
                display: flex;

                div,
                img {
                    flex: 1;
                }

                img {
                    width: 100%;
                    height: 650px;
                }

                div {
                    min-width: 700px;

                    h1 {
                        font-size: 70px;
                    }

                    p {
                        box-sizing: border-box;
                        padding-right: 50px;
                        font-size: 40px;
                    }
                }
            }

            .cardBoxMove {
                display: flex;
                padding: 30px;
                    box-sizing: border-box;
                .images {
                    flex: 1;
                    padding-top: 130px;
                    img{
                        width: 100%;
                        
                    }
                }

                .text {
                    flex: 3;
                    font-size: 45px;
                    h1{text-align: center;font-size: 65px;}
                    p{padding: 0 40px;}
                }
            }
        }

        h2 {
            text-align: center;
            font-size: 80px;
        }



        h2::before {
            content: "";
            width: 30%;
            height: 8px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 100px;
            left: 50%;
            transform: translateX(-50%);
            min-width: 100px;
            max-width: 100px;
        }
    }
}
</style>